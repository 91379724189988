.form-create {
    width: 100%;
    display: flex;
    justify-content: center;
}

.payment-search {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
    padding-right: 16px;
}